/**
 * A table.
 *
 * -- Props --
 * | Name       | Type    | Required  | Default Value | Description
 * |------------|---------|-----------|---------------|---------------
 * | id         | string  | false     | null          | An id to apply to this component.
 * | className  | string  | false     | ""            | Additional classes to apply to this component.
 * | headers    | array   | false     | []            | An array of table headers.
 * | rows       | array   | false     | []            | An array of rows for the table.
 * | footers    | array   | false     | []            | An array of table footers.
 *
 * -- Headers --
 * Each element of headers is an object with fields:
 * <pre>
 * {
 *     id: "", -- A unique id for the header
 *     className: "", -- A class to apply to the header
 *     value: <> -- The value to render as the table header
 * }
 * </pre>
 *
 * -- Rows --
 * Each element of rows is an array with form:
 * <pre>
 * {
 *     id: "", -- A unique id for the row
 *     className: "", -- A class to apply to the row
 *     values: [...] -- The row data
 * }
 * </pre>
 *
 * Each element of values is an object with form:
 * <pre>
 * {
 *     id: "", -- A unique id for the row data
 *     className: "", -- A class to apply to the row data
 *     value: <> -- The value to render as the row data
 * }
 * </pre>
 *
 * -- Footers --
 * Each element of footers is an object with form:
 * <pre>
 * {
 *     id: "", -- A unique id for the footer
 *     className: "", -- A class to apply to the footer
 *     value: <> -- The value to render as the table footer
 * }
 * </pre>
 */
export const Table = (props) => {
  let tableHeaders = null;
  if (props.headers) {
    tableHeaders = props.headers.map((header) => {
      return (
        <th className={header.className} key={header.id}>{header.value}</th>
      );
    });
  }

  let tableFooters = null;
  if (props.footers) {
    tableFooters = props.footers.map((footer) => {
      return (
        <th className={footer.className} key={footer.id}>{footer.value}</th>
      );
    });
  }

  let tableRows = null;
  if (props.rows) {
    tableRows = props.rows.map((row) => {
      const tableData = row.values.map((rowData) => {
        return (
          <td className={rowData.className} key={rowData.id}>{rowData.value}</td>
        );
      });

      return (
        <tr className={row.className} key={row.id}>{tableData}</tr>
      );
    });
  }

  return (
    <table id={props.id || null} className={`table${props.className ? " " + props.className : ""}`}>
      <thead><tr>{tableHeaders}</tr></thead>
      <tfoot><tr>{tableFooters}</tr></tfoot>
      <tbody>{tableRows}</tbody>
    </table>
  );
}

export default Table;
