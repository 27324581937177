/**
 * A tag.
 *
 * -- Props --
 * | Name       | Type    | Required  | Default Value | Description
 * |------------|---------|-----------|---------------|---------------
 * | id         | string  | false     | null          | An id to apply to this component.
 * | className  | string  | false     | ""            | Additional classes to apply to this component.
 */
export const Tag = (props) => {
  return (
    <span id={props.id || null} className={`tag${props.className ? " " + props.className : ""}`}
      data-tooltip={props.tooltip}>
      {props.children}
    </span>
  );
}

export default Tag;
