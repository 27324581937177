const PageHeader = () => {
  return (
    <section className="hero is-link is-small">
      <div className="hero-body">
        <p className="title">
          Schema Viewer
        </p>
        <p className="subtitle">
          View schema definitions for tracking events
        </p>
      </div>
    </section>
  );
};

export default PageHeader;