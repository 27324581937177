import * as Diff2Html from "diff2html";
import ReactHtmlParser from 'react-html-parser';

const Diff = require('diff');

/**
 * Generic json comparator component so that if we move away from ReactJsonViewCompare
 */
const JsonComparator = (props) => {

  const oldJson = props.json1 != null ? JSON.stringify(props.json1, null, 2) : "";
  const newJson = props.json2 ? JSON.stringify(props.json2, null, 2) : "";
  const diff = Diff.createTwoFilesPatch(' ', ' ', oldJson, newJson);
  const configuration = {
    inputFormat: "diff",
    drawFileList: false,
    matching: "lines",
    outputFormat: 'side-by-side',

  };
  const diffHtml = Diff2Html.html(diff, configuration);

  return (
  // We tried ReactJsonViewCompare, ReactDiffViewer, ReactGhLikeDiff but none worked (all different problems)
  // ended up doing it manually using jsdiff and diff2html with the help of ReactHtmlParser
    <div> { ReactHtmlParser (diffHtml) } </div>
  )

}

export default JsonComparator;