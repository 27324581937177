import { AuthenticatedTemplate } from '@azure/msal-react';

import { Login } from '../../Login';
import Header from "../header/Header";
import EventViewer from "./EventViewer";

export const EventViewerComponent = () => {
  return (
    <>
      <Login/>

      <AuthenticatedTemplate>
        <div className="container">
          <Header/>
          <hr />
          <EventViewer/>
        </div>
      </AuthenticatedTemplate>
    </>
  );
}