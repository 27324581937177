import ModalCard from "../../components/common/elements/ModalCard";
import JsonComparator from "./JsonComparator";
import useFetchWithMsal from "../../useFetchWithMsal";
import { event_schema_api_urls } from "./common/Constants";
import Select from "react-select";
import { useEffect, useState } from "react";
import "../css/SchemaVersionComparator.css";

/**
 * Compare two schemas
 */
const SchemaVersionComparator = (props) => {

  const [schemaVersionOptionSelected1, setSchemaVersionOptionSelected1] = useState(undefined);
  const [schemaVersionOptionSelected2, setSchemaVersionOptionSelected2] = useState(props.schemaVersionOptionSelected);

  const [schemaDefinition1, setSchemaDefinition1] = useState(null);
  const [schemaDefinition2, setSchemaDefinition2] = useState(null);

  const { execute } = useFetchWithMsal();

  useEffect(() => {
    setSchemaDefinition1(null)
    setSchemaDefinition2(null)

    if (schemaVersionOptionSelected1 && schemaVersionOptionSelected2) {
      getSchemaDefinitions();
    }
  }, [schemaVersionOptionSelected1, schemaVersionOptionSelected2]);


  const getSchemaDefinitions = () => {
    const splitVersion1 = schemaVersionOptionSelected1.label.split(".")
    const splitVersion2 = schemaVersionOptionSelected2.label.split(".")

    const postBody1 = {
      "rawSchemaName": props.schemaName,
      "majorSchemaVersion": splitVersion1[0],
      "minorSchemaVersion": splitVersion1[1]
    };
      // + "?schema-name=" +  + "&schema-version=" + schemaVersionSelected1
    execute('POST', event_schema_api_urls.getSpecificSchemaDefinition, postBody1)
      .then((data) => {
        setSchemaDefinition1(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    const postBody2 = {
      "rawSchemaName": props.schemaName,
      "majorSchemaVersion": splitVersion2[0],
      "minorSchemaVersion": splitVersion2[1]
    };
    execute('POST', event_schema_api_urls.getSpecificSchemaDefinition, postBody2)
      .then((data) => {
        setSchemaDefinition2(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  return (
    <ModalCard
      title={"Comparing Versions for " + props.schemaName}
      onClose={props.onClose}
      className={`${props.className} version-comparator`}
    >
      <div className={`columns ${!schemaDefinition1 || !schemaDefinition2 ? "expanded-comparator-body" : ""}`}>
        <div className="column is-half">
          <Select
            className="basic-single"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            options={props.schemaVersionOptions}
            value={schemaVersionOptionSelected1}
            onChange={(selected) => {
              setSchemaVersionOptionSelected1(selected)
            }}
            placeholder={"Select version 1"}
          />
        </div>
        <div className="column is-half">
          <Select
            className="basic-single"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            options={props.schemaVersionOptions}
            value={schemaVersionOptionSelected2}
            onChange={(selected) => {
              setSchemaVersionOptionSelected2(selected)
            }}
            placeholder={"Select version 2"}
          />
        </div>

      </div>
      {schemaDefinition1 && schemaDefinition2 &&
            <JsonComparator
              title1={"Version " + schemaVersionOptionSelected1.label}
              json1={schemaDefinition1}
              title2={"Version " + schemaVersionOptionSelected2.label}
              json2={schemaDefinition2}/>
      }
    </ModalCard>
  );
}

export default SchemaVersionComparator;