const Header = () => {

  return (
    <section className="hero is-link is-small">
      <div className="hero-body">
        <p className="title">
          Event Viewer
        </p>
        <p className="subtitle">
          View live tracking events
        </p>
      </div>
    </section>
  );
};

export default Header;