import Select from "react-select";
import Box from "../../components/common/elements/Box";
import Icon from "../../components/common/elements/Icon";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import "../css/EventViewerForm.css";
import Button from "../../components/common/elements/Button";
import Level from "../../components/common/layout/Level";
import LevelLeft from "../../components/common/layout/LevelLeft";
import LevelRight from "../../components/common/layout/LevelRight";
import LevelItem from "../../components/common/layout/LevelItem";

/**
 * Input form for monitoring Kinesis streams.
 *
 * -- Props --
 * | Name               | Type        | Required  | Default Value | Description
 * |--------------------|-------------|-----------|---------------|---------------
 * | id                 | string      | false     | null          | An id to apply to this component.
 * | className          | string      | false     | ""            | Additional classes to apply to this component.
 * | streamsLoading     | boolean     | false     | false         | A boolean indicating of the stream list is still loading.
 * | streams            | array       | true      | []            | An array of stream names that can be monitored.
 * | selectedStreams    | array       | true      | []            | An array of stream selected for monitoring.
 * | onStreamChange     | function    | true      | null          | A handler called when the selected streams change.
 * | searchText         | string      | true      | ""            | The event search text.
 * | onSearchChange     | function    | true      | null          | A handler called when the search text changes.
 * | findAll            | boolean     | false     | false         | A flag indicating that all events on the stream should be returned.
 * | onFindAllChange    | function    | true      | null          | A handler called when the find all toggle is changed.
 * | monitoring         | boolean     | false     | false         | A boolean indicating if monitoring is in progress.
 * | onMonitoringChange | function    | true      | null          | A handler when one of the monitoring buttons is clicked. Accepts the boolean monitoring state.
 */
export const EventViewerForm = (props) => {

  // Process props
  const streams = props.streams ? props.streams : [];
  const selectedStreams = props.selectedStreams ? props.selectedStreams : [];
  const streamsLoading = props.streamsLoading ? props.streamsLoading : false;
  const searchText = props.searchText ? props.searchText : "";
  const findAll = props.findAll ? props.findAll : false;
  const monitoring = props.monitoring ? props.monitoring : false;

  // Tips to display
  const streamingTip = (
    <div className="stream-reminder icon-text">
      <Icon className="has-text-info" icon={faInfoCircle}/>
      <span>Events are monitored in real time, make sure to generate the events after you start monitoring</span>
    </div>
  );

  // Prepare the stream selectors
  const streamSelectOptions = [];
  const selectedStreamOptions = [];
  for (let stream of streams) {
    streamSelectOptions.push({
      label: stream,
      value: stream
    });
  }

  for (let stream of selectedStreams) {
    selectedStreamOptions.push({
      label: stream,
      value: stream
    });
  }

  const streamSelect = (
    <Select placeholder="Select Kinesis streams to monitor"
      isLoading={streamsLoading}
      className="basic-single is-full"
      classNamePrefix="stream-select"
      isClearable={true}
      isSearchable={true}
      isMulti
      options={streamSelectOptions}
      value={selectedStreamOptions}
      onChange={props.onStreamChange}
      isDisabled={monitoring}
    />
  );

  // Event search box
  const eventSearch = (
    <div className="field">
      <label className="label">Search</label>
      <div className="field">
        <label className="checkbox" disabled={monitoring}>
          <input type="checkbox"
            checked={findAll}
            onChange={props.onFindAllChange}
            disabled={monitoring}
            data-tooltip="Enable to find and return all events on the selected streams"
          />
          {" Find all events "}
        </label>
      </div>
      <div className="control">
        <input className="input"
          type="text"
          placeholder="Find events that contain this value"
          value={searchText}
          onChange={props.onSearchChange}
          disabled={findAll || monitoring}
        />
      </div>
    </div>
  );

  // Monitoring controls
  let canStartMonitoring = selectedStreams.length > 0 && (findAll || (searchText != null && searchText.length > 0));
  const monitoringControls = (
    <div className="field">
      <div className="control">
        <Level>
          <LevelLeft/>
          <LevelRight>
            <LevelItem>
              <Button className={`is-link ${monitoring ? "is-loading" : ""}`}
                onClick={() => { props.onMonitoringChange(true) }}
                disabled={!canStartMonitoring}
              >
              Start Monitoring
              </Button>
            </LevelItem>
            <LevelItem>
              <Button className={"is-warning is-light"}
                onClick={() => { props.onMonitoringChange(false) }}
                disabled={!monitoring}
              >
              Stop Monitoring
              </Button>
            </LevelItem>
          </LevelRight>
        </Level>
      </div>
    </div>
  );

  return (
    <div id={props.id} className={`event-viewer-form${props.className ? " " + props.className : ""}`}>
      <div className="stream-select">
        <Box>
          <h3 className="title is-3">Stream Monitoring</h3>
          <h5 className="subtitle is-5">Monitor events on any Kinesis stream</h5>
          {streamingTip}
          <div className="field">
            <label className="label">Kinesis Streams</label>
            <div className="control">
              {streamSelect}
            </div>
          </div>
          <hr/>
          {eventSearch}
          {monitoringControls}
        </Box>
      </div>
    </div>
  );

}

export default EventViewerForm;