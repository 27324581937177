import React, { useState } from "react";

import "../../../css/common/elements/Snippet.css";

/**
 * Display a sample of a larger text that matches the given search expression.
 *
 * -- Props --
 * | Name       | Type    | Required  | Default Value | Description
 * |------------|---------|-----------|---------------|---------------
 * | id         | string  | false     | null          | An id to apply to this component.
 * | className  | string  | false     | ""            | Additional classes to apply to this component.
 * | text       | string  | true      | N/A           | The full text.
 * | pattern    | string  | false     | ""            | A regex to search for in the text.
 * | padding    | number  | false     | 50            | The number of characters to show around each match.
 * | maxLength  | number  | false     | inf           | The maximum number of characters to display.
 */
export const Snippet = (props) => {
  const [paddingModifiers, setPaddingModifiers] = useState({});

  const updatePaddingModifier = (key) => {
    const modifier = (paddingModifiers[key] || 1) + 1;

    setPaddingModifiers({ ...paddingModifiers, [key]: modifier });
  }

  const padding = props.padding || 50;
  const text = props.text;
  const pattern = props.pattern;
  const maxLength = props.maxLength;

  const buildEllipsis = (key) => {
    return (
      <span key={"ellipsis" + key} className="ellipsis" onClick={() => updatePaddingModifier(key)}>...</span>
    );
  }

  // Build the regex pattern
  let regex;
  try {
    regex = new RegExp(pattern, "gi");
  } catch (e) {
    regex = new RegExp("", "gi");
  }

  // Find all matching text
  const matches = text.matchAll(regex);
  const matchData = [];
  for (const match of matches) {
    if (match[0]) {
      matchData.push({
        start: match.index,
        end: match.index + Math.max(match[0].length - 1, 0),
        text: match[0]
      });
    }
  }

  // Determine what text to display based on the match data
  const sections = [];
  let prefixBarrier = 0; // Prefix can start at, but not go before the prefix barrier index
  let suffixBarrier = text.length - 1; // Suffix can end at, but not go past the suffix barrier index
  let previousMatchHadSuffixEllipsis = false;
  let charCount = 0;
  let extraMatches = 0;
  for (const [index, match] of matchData.entries()) {
    const matchStart = match.start;
    const matchEnd = match.end;
    const matchText = match.text;

    let hasPrefixEllipsis = false;
    let hasSuffixEllipsis = false;

    const prefixModifierKey = "prefix" + index;
    let prefixModifier = paddingModifiers[prefixModifierKey] || 1;

    // Start the prefix "padding" characters before the match, or at the prefix barrier
    const prefixStart = Math.max(matchStart - (padding * prefixModifier), prefixBarrier);
    let prefix = text.slice(prefixStart, matchStart);

    // Display an ellipsis before the prefix if there are "hidden" characters between the last suffix end and the start of the prefix
    if (prefixStart > prefixBarrier && !previousMatchHadSuffixEllipsis) {
      hasPrefixEllipsis = true;
    }

    // The suffix barrier is either at the start of the next match, or "padding" chars past the match end
    if (index < matchData.length - 1) {
      suffixBarrier = matchData[index + 1].start;
    } else {
      suffixBarrier = Math.max(matchEnd + padding, text.length);
    }

    const suffixModifierKey = "suffix" + index;
    let suffixModifier = paddingModifiers[suffixModifierKey] || 1;

    // The suffix ends "padding" characters after the match, or at the suffix barrier
    const suffixStart = matchEnd + 1
    const suffixEnd = Math.min(suffixStart + (padding * suffixModifier), suffixBarrier);
    let suffix = text.slice(suffixStart, suffixEnd);

    // Display an ellipsis after the suffix if there are "hidden" characters between the suffix end and the suffix barrier
    if (suffixEnd < suffixBarrier) {
      hasSuffixEllipsis = true;
      previousMatchHadSuffixEllipsis = true;
    } else {
      previousMatchHadSuffixEllipsis = false;
    }

    // The next prefix barrier starts after the current suffix
    prefixBarrier = suffixEnd + 1;

    if (hasPrefixEllipsis) {
      sections.push(buildEllipsis(prefixModifierKey));
    }

    sections.push(<span key={prefix + index + "prefix"}>{prefix}</span>);
    sections.push(<span key={matchText + index + "match"} className="match-text">{matchText}</span>);
    sections.push(<span key={suffix + index + "suffix"}>{suffix}</span>);

    if (hasSuffixEllipsis) {
      sections.push(buildEllipsis(suffixModifierKey));
    }

    const trailModifierKey = "...trail";
    let allowedExtraMatches = (paddingModifiers[trailModifierKey] || 1) - 1; // Start with 0 extra matches
    charCount += (prefix.length + matchText.length + suffix.length);

    // Stop if we have a char limit, the char limit is reached, there is another match still, and there are more characters to display
    const limitReached = maxLength && charCount >= maxLength && index < matchData.length - 1 && charCount < text.length;
    if (limitReached) {
      // Each time the trail ellipsis is clicked, display the next match, otherwise stop
      if (extraMatches < allowedExtraMatches) {
        extraMatches++;
      } else {
        if (!previousMatchHadSuffixEllipsis) {
          sections.push(buildEllipsis(trailModifierKey));
        }
        break;
      }
    }
  }

  // Fallback if no pattern was supplied
  if (!pattern) {
    let displayText = text;
    const endModifierKey = "end";
    let endModifier = paddingModifiers[endModifierKey] || 1;

    // Trim the text if there is a display limit
    if (maxLength && text.length > maxLength) {
      displayText = text.slice(0, (maxLength - padding) + (padding * endModifier));
    }

    sections.push(<span key={displayText}>{displayText}</span>);

    if (displayText.length < text.length) {
      sections.push(buildEllipsis(endModifierKey));
    }
  }

  return (
    <div id={props.id || null} className={`snippet${props.className ? " " + props.className : ""}`}>
      {sections}
    </div>
  );
}

export default Snippet;
