import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css'
import '../../index.css'
import '../css/schema.css';
import { useEffect, useRef, useState } from "react";
import SchemaUtil from "../utils/SchemaUtil";

const SchemaDefinition = ({ schemaDefinition, schemaName, schemaVersion }) => {
  // Depth used for expanded JSON (shows until 1000th nested fields)
  const defaultExpandedDepth = 1000
  // JSON depth from which we want to collapse (1 for first level objects, for example)
  const defaultCollapsedDepth = 1

  const [collapse, setCollapse] = useState(defaultExpandedDepth);
  const [showTooltip, setShowTooltip] = useState(false);
  const [copied, setCopied] = useState(false);
  const repoUrl = schemaDefinition?.['repoUrl'];
  const navbarRef = useRef(null);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    // enterprise tracking schemas are too big, it'll hide the metrics
    if(SchemaUtil.isUserTrackingFeed(schemaDefinition)) {
      setCollapse(getCollapsedDepth())
    } else {
      // for other schemas, we want to expand when updating the definition
      setCollapse(getExpandedDepth());
    }

    window.addEventListener('scroll', handleScroll);
  }, [schemaDefinition]);

  const handleScroll = () => {
    if (navbarRef.current) {
      const rect = navbarRef.current.getBoundingClientRect();
      if (rect.top <= 25) { // can't put 0 here, because there's an edge case when it's not sticky yet, the top tooltip is still not visible in the viewport
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
  };

  const expandAll = (() => {
    setCollapse(getExpandedDepth())
    setShowTooltip(true);
  });

  function getExpandedDepth() {
    // Only needed for the case were some fields were collapsed manually
    const updateExpandedDepth = 1001

    return collapse === defaultExpandedDepth ? updateExpandedDepth : defaultExpandedDepth;
  }


  function getCollapsedDepth() {
    // Only needed for the case were some fields were expanded manually
    const updateCollapsedDepth = 1.1

    return collapse === defaultCollapsedDepth ? updateCollapsedDepth : defaultCollapsedDepth;
  }

  /**
   * Collapse the json to the 1st depth
   *
   * Original full json -
   * {
   *   "geo": {
   *     "g1": {
   *       ...
   *     }
   *   }
   *   "page": {
   *     "p1": {
   *       ...
   *     }
   *   }
   * }
   *
   * Collapsed json -
   * {
   *   "geo": {...}
   *   "page": {...}
   * }
   */
  const collapseAll = () => {
    setCollapse(getCollapsedDepth())
    setShowTooltip(true);
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(schemaDefinition, null, 2))
      .then(() => {
        setCopied(true);
      });
  };

  const disableCopied = () => {
    setCopied(false);
  }

  const disableToolTip = () => {
    setShowTooltip(false);
  }

  const isExpanded = () => {
    return collapse >= defaultExpandedDepth
  }

  const isCollapsed = () => {
    return collapse < defaultExpandedDepth
  }

  return (
    schemaDefinition &&
      <>
        <nav className="navbar level pt-2 pb-2 sticky is-full" ref={navbarRef}>
          <div className="level-left">
            <div className="level-item">
              <button className={`button is-link is-outlined is-fullwidth ${sticky ? "has-tooltip-bottom" : ""}`}
                data-tooltip={isExpanded() && showTooltip ? "Expanded!": null}
                onClick={expandAll}
                onMouseLeave={disableToolTip}>
                Expand All
              </button>
            </div>
            <div className="level-item">
              <button className={`button is-link is-outlined is-fullwidth ${sticky ? "has-tooltip-bottom" : ""}`}
                data-tooltip={isCollapsed() && showTooltip ? "Collapsed!": null}
                onClick={collapseAll}
                onMouseLeave={disableToolTip}>
                Collapse
              </button>
            </div>
            <div className="level-item">
              <button className={`button is-link is-outlined is-fullwidth ${sticky ? "has-tooltip-bottom" : ""}`}
                data-tooltip={copied ? "Copied!": null}
                onClick={copyToClipboard}
                onMouseLeave={disableCopied}>
                Copy to Clipboard
              </button>
            </div>
            <div className="level-item">
              {
                repoUrl &&
                  <a className={`button is-text is-link is-outlined is-fullwidth ${sticky ? "has-tooltip-bottom" : ""}`}
                    href={repoUrl} target="_blank" rel="noreferrer">
                    Git repo
                  </a> ||
                  <div className={`button is-disabled is-outlined is-fullwidth ${sticky ? "has-tooltip-bottom" : ""}`} data-tooltip="Git repo link disabled because this is not a Kinesis feed or it doesn't contain a repoUrl" disabled>
                    Git repo
                  </div>
              }
            </div>
            <div className="level-item">
              {SchemaUtil.isKinesisDriven(schemaDefinition) && repoUrl &&
                  <a className={`button is-text is-link is-outlined is-fullwidth ${sticky ? "has-tooltip-bottom" : ""}`}
                    href={"https://tripdocs.pages.tamg.io/data-platform/docs/tracking/enterprisetracking/gen4/schemalist/" + SchemaUtil.getSchemaPathFromRepoUrl(repoUrl)}
                    target="_blank" rel="noreferrer">
                    Tripdocs
                  </a> ||
                  <div className={`button is-outlined is-fullwidth ${sticky ? "has-tooltip-bottom" : ""}`} data-tooltip="Tripdocs link disabled because this is not a Kinesis feed or it doesn't contain a repoUrl" disabled>
                    Tripdocs
                  </div>
              }
            </div>
          </div>
        </nav>
        <div className="tile">
          <JsonView src={schemaDefinition}
            collapsed={collapse}
            enableClipboard={false}
          />
        </div>
      </>
  )
}

export default SchemaDefinition;