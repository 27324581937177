import React, { useEffect, useState } from "react";
import useFetchWithMsal from "../../useFetchWithMsal";
import EventViewerForm from "./EventViewerForm";
import EventDisplay from "./EventDisplay";


export const EventViewer = (props) => {
  const { error, execute } = useFetchWithMsal();
  const [searchFilter, setSearchFilter] = useState("");
  const [streamsLoading, setStreamsLoading] = useState(true);
  const [streams, setStreams] = useState([]);
  const [streamsToMonitor, setStreamsToMonitor] = useState([]);
  const [findAll, setFindAll] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventPoller, setEventPoller] = useState(null);

  const FIND_ALL_SEARCH_FILTER = "{";

  // Fetch the Kinesis streams
  useEffect(() => {
    execute("GET", "event-viewer/v1/streams")
      .then((response) => {
        if (response) {
          const streams = new Set();
          for (let streamDetails of response) {
            streams.add(streamDetails["streamName"]);
          }
          setStreams([...streams]);
          setStreamsLoading(false);
        }
      });
  }, [execute]);

  if (error) {
    return (
      <div className="notification is-danger">
        <p>There was an issue loading this page. Please refresh the page to try again.</p>
        <p>Error: {error.message}</p>
      </div>
    );
  }

  const fetchEvents = () => {
    const streams = encodeURIComponent(streamsToMonitor);
    const searchText = findAll ? FIND_ALL_SEARCH_FILTER : searchFilter;

    fetch(`event-viewer/v1/events/${searchText}?streams=${streams}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        if (!responseJson) {
          return;
        }

        setEvents(responseJson);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }

  const handleStreamSelect = (selectedStreamOptions) => {
    const selectedStreams = [];
    for (let options of selectedStreamOptions) {
      selectedStreams.push(options['value']);
    }
    setStreamsToMonitor(selectedStreams);
  }

  const handleFindAllChange = (event) => {
    setFindAll(!findAll);
  }

  const handleSearchFilterChange = (event) => {
    setSearchFilter(event.target.value);
  }

  const handleMonitoringChange = (monitorState) => {
    if (monitorState && !eventPoller) {
      setEvents([]);

      // Start monitoring the selected streams with the given filter
      const searchText = findAll ? FIND_ALL_SEARCH_FILTER : searchFilter;
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ streamNames: streamsToMonitor })
      };
      fetch(`event-viewer/v1/filter/${searchText}`, requestOptions);

      // Start polling for events
      fetchEvents();
      const eventPoller = setInterval(() => {
        fetchEvents();
      }, 15000);
      setEventPoller(eventPoller);
    } else if (!monitorState) {
      clearInterval(eventPoller);
      setEventPoller(null);
    }
  }

  let eventDisplay = null;
  let spacer = null;
  if (events != null && events.length > 0) {
    let streamEvents = {};
    for (let i = 0; i < events.length; i++) {
      const streamName = events[i]['streamName'];
      streamEvents[streamName] = events[i]['events'];
    }

    spacer = (
      <hr />
    );
    eventDisplay = (
      <EventDisplay events={streamEvents} />
    );
  }

  return (
    <div className="event-viewer">
      <EventViewerForm streamsLoading={streamsLoading}
        streams={streams}
        selectedStreams={streamsToMonitor}
        onStreamChange={handleStreamSelect}
        findAll={findAll}
        onFindAllChange={handleFindAllChange}
        searchText={searchFilter}
        onSearchChange={handleSearchFilterChange}
        monitoring={eventPoller != null}
        onMonitoringChange={handleMonitoringChange}
      />
      { spacer }
      { eventDisplay }
    </div>
  );
}


export default EventViewer;