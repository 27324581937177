const event_schema_api_prefix = 'event-schema/v2/'

const event_schema_api_urls = {
  listAllSchemaNamesAndMajorVersions: event_schema_api_prefix + 'listAllSchemaNamesAndMajorVersions',
  listAllMinorVersions: event_schema_api_prefix + 'listAllMinorVersions',
  getSpecificSchemaDefinition: event_schema_api_prefix + 'getSpecificSchemaDefinition',
  validate: event_schema_api_prefix + 'validate',
  getEventIngestionCounter: event_schema_api_prefix + 'getEventIngestionCounter'
}

export { event_schema_api_urls }