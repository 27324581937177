import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * An icon.
 *
 * -- Props --
 * | Name         | Type        | Required    | Default Value | Description
 * |--------------|-------------|-------------|---------------|---------------
 * | id           | string      | false       | null          | An id to apply to this component.
 * | className    | string      | false       | ""            | Additional classes to apply to this component.
 * | icon         | component   | true        | N/A           | The icon component to render.
 * | onClick      | function    | false       | null          | An onClick function.
 * | onMouseLeave | function    | false       | null          | An onMouseLeave function.
 * | tooltip      | string      | false       | null          | A tooltip to display.
 */
export const Icon = (props) => {
  return (
    <span id={props.id || null}
      className={`icon${props.className ? " " + props.className : ""}`}
      onClick={props.onClick}
      onMouseLeave={props.onMouseLeave}
      data-tooltip={props.tooltip}
    >
      <FontAwesomeIcon icon={props.icon} />
    </span>
  );
}

export default Icon;
