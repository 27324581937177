import ModalCard from "../../components/common/elements/ModalCard";
import JsonView from "react18-json-view";
import Button from "../../components/common/elements/Button";
import Icon from "../../components/common/elements/Icon";
import { useState } from "react";
import TagGroup from "../../components/common/elements/TagGroup";
import Tags from "../../components/common/elements/Tags";
import Tag from "../../components/common/elements/Tag";

import { faClipboardList, faLeftLong, faRightLong } from "@fortawesome/free-solid-svg-icons";

import "../css/EventModal.css"

/**
 * A modal for displaying a full event payload.
 *
 * -- Props --
 * | Name       | Type      | Required  | Default Value | Description
 * |------------|-----------|-----------|---------------|---------------
 * | id         | string    | false     | null          | An id to apply to this component.
 * | className  | string    | false     | ""            | Additional classes to apply to this component.
 * | eventJson  | string    | true      | N/A           | The event json to display.
 * | index      | number    | true      | N/A           | The event index.
 * | hasPrev    | boolean   | true      | N/A           | A boolean indicating if there is a previous event to show.
 * | onPrev     | function  | true      | N/A           | A function that is called when the Prev button is clicked.
 * | hasNext    | boolean   | true      | N/A           | A boolean indicating if there is a next event to show.
 * | onNext     | function  | true      | N/A           | A function that is called when the Next button is clicked.
 * | onClose    | function  | true      | N/A           | An onClose function.
 */
export const EventModal = (props) => {
  const [copied, setCopied] = useState(false);

  const event = JSON.parse(props.eventJson);
  const formattedEvent = JSON.stringify(event, null, 2);

  const eventId = event.event_id;
  const producer = event.producer_ref;
  const eventTime = event.event_timestamp;
  const schema = event.schema;
  const schemaSections = schema.split("/");
  let schemaName = schemaSections[0];
  let schemaMinorVersion = schemaSections[1];

  const schemaMatchResults = schemaName.match(/(?<name>.*)__(?<version>\d+)$/);
  let rootSchemaName = schemaName;
  let schemaMajorVersion = "1";
  if (schemaMatchResults) {
    rootSchemaName = schemaMatchResults.groups.name;
    schemaMajorVersion = schemaMatchResults.groups.version;
  }

  const schemaVersion = `${schemaMajorVersion}.${schemaMinorVersion}`;

  const prevBtn = (
    <Button className="is-link" onClick={props.onPrev} disabled={!props.hasPrev}>
      <Icon icon={faLeftLong}/>
      <span>Previous</span>
    </Button>
  )

  const nextBtn = (
    <Button className="is-link" onClick={props.onNext} disabled={!props.hasNext}>
      <span>Next</span>
      <Icon icon={faRightLong}/>
    </Button>
  );

  const footer = [
    (
      <Button key="modal-copy-btn" className="is-link"
        onClick={() => {navigator.clipboard.writeText(formattedEvent); setCopied(true)} }
        onMouseLeave={() => setCopied(false)}
        tooltip={copied ? "Copied!" : null}
      >
        <Icon icon={faClipboardList}/>
        <span>Copy</span>
      </Button>
    ),
    (
      <div key="modal-nav-container">
        {prevBtn}
        {nextBtn}
      </div>
    )
  ];

  return (
    <ModalCard id={props.id || null}
      className={`event-modal${props.className ? " " + props.className : ""}`}
      title="Event Viewer"
      footer={footer}
      onClose={props.onClose}
    >
      <TagGroup>
        <div className="control">
          <Tags className="has-addons">
            <Tag className="is-info">Index</Tag>
            <Tag>{props.index}</Tag>
          </Tags>
        </div>
        <div className="control">
          <Tags className="has-addons">
            <Tag className="is-info">Schema</Tag>
            <Tag>{rootSchemaName}</Tag>
          </Tags>
        </div>
        <div className="control">
          <Tags className="has-addons">
            <Tag className="is-info">Version</Tag>
            <Tag>{schemaVersion}</Tag>
          </Tags>
        </div>
        <div className="control">
          <Tags className="has-addons">
            <Tag className="is-info">Producer</Tag>
            <Tag>{producer || "N/A"}</Tag>
          </Tags>
        </div>
        <div className="control">
          <Tags className="has-addons">
            <Tag className="is-info">Event Time</Tag>
            <Tag>{eventTime}</Tag>
          </Tags>
        </div>
        <div className="control">
          <Tags className="has-addons">
            <Tag className="is-info">Event ID</Tag>
            <Tag>{eventId}</Tag>
          </Tags>
        </div>
      </TagGroup>
      <hr/>
      <JsonView src={JSON.parse(props.eventJson)}
        enableClipboard={false}
      />
    </ModalCard>
  );
}

export default EventModal;
