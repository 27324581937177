import Modal from "./Modal";

/**
 * A modal card.
 *
 * -- Props --
 * | Name       | Type      | Required  | Default Value | Description
 * |------------|-----------|-----------|---------------|---------------
 * | id         | string    | false     | null          | An id to apply to this component.
 * | className  | string    | false     | ""            | Additional classes to apply to this component.
 * | title      | string    | false     | ""            | The modal title.
 * | footer     | *         | false     | null          | Any content to render in the footer.
 * | onClose    | function  | true      | N/A           | An onClose function.
 */
export const ModalCard = (props) => {
  return (
    <Modal id={props.id || null} className={`${props.className ? " " + props.className : ""}`} onClose={props.onClose}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{props.title || ""}</p>
          <button className="delete" onClick={props.onClose}></button>
        </header>
        <section className="modal-card-body">
          {props.children}
        </section>
        <footer className="modal-card-foot">
          {props.footer}
        </footer>
      </div>
    </Modal>
  );
}

export default ModalCard;
