import { MsalProvider } from '@azure/msal-react';
import { EventViewerComponent } from './stream/components/EventViewerComponent';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageLayoutSchema } from "./schema/components/PageLayoutSchema";
import { GlobalNavBar } from "./components/GlobalNavBar";
import { HomeComponent } from "./home/components/HomeComponent";

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
  return (
    <BrowserRouter>
      <GlobalNavBar />
      <Routes>
        <Route exact={true} path="/" element={
          <MsalProvider instance={instance}>
            <HomeComponent/>
          </MsalProvider>
        }></Route>
        <Route exact={true} path="/event-viewer" element={
          <MsalProvider instance={instance}>
            <EventViewerComponent>
            </EventViewerComponent>
          </MsalProvider>
        }></Route>
        <Route exact={true} path="/event-schema" element={
          <MsalProvider instance={instance}>
            <PageLayoutSchema>
            </PageLayoutSchema>
          </MsalProvider>
        }></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;