import Select from "react-select";

/**
 * A labeled SELECT. will look something like [LABEL][SELECT].
 * <p>
 *  NOTE: All props below are required
 *  <p>
 * -- Props --
 * |     Name     |   Type   |                                   Description                                  |
 * |:------------:|:--------:|:------------------------------------------------------------------------------:|
 * | label        | string   | The label text.                                                                |
 * | loading      | boolean  | A boolean indicating if the options are loading.                               |
 * | isClearable  | boolean  | A boolean indicating if the select is clearable.                               |
 * | isSearchable | boolean  | A boolean indicating if the select is searcheable.                             |
 * | options      | list     | The list of options. Each option should be an object with 'value' and 'label'. |
 * | value        | object   | The selected value variable. Should match an entry of the options list.        |
 * | onChange     | function | A function that is called on selection changed.                                |
 * | placeholder  | string   | The placeholder text to show if nothing is selected.                           |
 * | isDisabled   | boolean  | A boolean indicating if the select should be disabled.                         |
 */
const LabelAndSelect = (props) => {
  return (
    <div className="field is-horizontal has-addons columns">
      <p className="control column pr-0">
        {/* Tried using a static button, which looks a bit better than the current version, but the height was slightly off.
             For that reason I'm using a disabled select so that the heights match exactly...*/}
        {/*<span className="button is-static">{props.label}</span>*/}
        <Select
          className="basic-single e-caret-hide"
          isClearable={false}
          isSearchable={false}
          placeholder={props.label}
          isDisabled={true}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null
          }}
        />
      </p>
      <p className="control column pl-1 is-8">
        {props.loading ?
          <button className="button is-fullwidth is-loading"/> :
          <Select
            className="basic-single is-full"
            classNamePrefix="select"
            isClearable={props.isClearable}
            isSearchable={props.isSearchable}
            options={props.options}
            value={props.value}
            key={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            isDisabled={props.isDisabled}
          />
        }
      </p>
    </div>
  )
}

export default LabelAndSelect;