import { useState } from 'react';
import useFetchWithMsal from "../../useFetchWithMsal";
import { event_schema_api_urls } from "./common/Constants";
import JsonView from "react18-json-view";

/**
 * Validate json against schema
 * @param schemaName name of the schema to use in the validation
 * @param schemaVersion version of the schema to use in the validation
 * @param rawJsonString json need to be validated against schema
 * @returns validator component
 */

const JsonSchemaValidator = ({ schemaName, schemaVersion, rawJsonString }) => {
  const [validationData, setValidationData] = useState('');
  const [json, setJson] = useState({});
  const [prettifyError, setPrettifyError] = useState('');
  const { isLoading, execute } = useFetchWithMsal();

  const validate = () => {
    try {
      const newJson = JSON.parse(rawJsonString);
      setJson(() => {
        const splitVersion = schemaVersion.label.split(".")
        const postBody = {
          "rawSchemaName": schemaName,
          "majorSchemaVersion": splitVersion[0],
          "minorSchemaVersion": splitVersion[1],
          "json": JSON.stringify(newJson)
        };

        execute('POST', event_schema_api_urls.validate, postBody)
          .then((data) => {
            setValidationData(data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            setValidationData(error);
          });

        return newJson;
      });
      setPrettifyError(null);
    } catch (e) {
      setPrettifyError(e.message);
      setJson({});
    }
  };

  return (
    <>
      <div>
        { isLoading ?
          (<button className="button is-link mt-4 mb-4 is-fullwidth is-outlined is-loading">Loading</button>) :
          (<button className="button is-info mt-4 mb-4 is-fullwidth is-outlined" onClick={validate} disabled={!schemaName || !schemaVersion}>
            Validate
          </button>)
        }
      </div>
      <div>
        { prettifyError && <>
          <div className="notification is-danger mt-4">
            {prettifyError}
          </div>
        </>}
      </div>
      <div>
        { !prettifyError && validationData && Array.isArray(validationData) && (
          <div className="notification is-danger mt-4 mb-4">
            {validationData.map((line, i) => (
              <p key={i}>{line}</p>
            ))}
          </div>
        )}
      </div>
      <div>
        { !prettifyError && validationData && !Array.isArray(validationData) && (
          <div className="notification is-success mt-4 mb-4">
            {validationData}
          </div>)}
      </div>
      <div>
        {
          Object.keys(json).length !== 0 &&
          <JsonView src={json}
            collapsed={1}
            enableClipboard={false}
          />
        }
      </div>
    </>
  );
}

export default JsonSchemaValidator;