import Button from "../../components/common/elements/Button";
import Icon from "../../components/common/elements/Icon";
import Box from "../../components/common/elements/Box";

/**
 * A card for displaying information on the home page.
 *
 * -- Props --
 * | Name         | Type        | Required  | Default Value | Description
 * |--------------|-------------|-----------|---------------|---------------
 * | id           | string      | false     | null          | An id to apply to this component.
 * | className    | string      | false     | ""            | Additional classes to apply to this component.
 * | title        | string      | true      | N/A           | The card title.
 * | description  | string      | true      | N/A           | The card description.
 * | href         | string      | true      | N/A           | The target to link to from the card.
 * | icon         | component   | true      | N/A           | The icon to display on the link button.
 * | buttonText   | string      | true      | N/A           | The link button text.
 * | buttonTarget | string      | false     | _blank        | The button target.
 */
export const HomeCard = (props) => {
  return (
    <Box id={props.id} className={`home-card${props.className ? " " + props.className : ""}`}>
      <div className="home-card-info">
        <p className="title">{props.title}</p>
        <p className="content">
          <p>{props.description}</p>
        </p>
      </div>
      <Button className="is-link" href={props.href} target={props.buttonTarget}>
        <Icon icon={props.icon}/>
        <span>{props.buttonText}</span>
      </Button>
    </Box>
  );
}

export default HomeCard;
