class SchemaUtil {

  static isKinesisDriven = (schemaDefinition) => {
    return schemaDefinition && schemaDefinition.stream
  }

  static containsStream = (stream, schemaDefinition) => {
    return SchemaUtil.isKinesisDriven(schemaDefinition) && (schemaDefinition.stream === stream ||
            (schemaDefinition.additionalStreams && schemaDefinition.additionalStreams.includes(stream)))
  }

  static isRETL = (schemaDefinition) => {
    const rETLStream = "ReverseETLStream"
    return SchemaUtil.containsStream(rETLStream, schemaDefinition)
  }

  static isUserTrackingFeed = (schemaDefinition) => {
    const userTrackingStreams = ["DEV-UserTrackingStream", "QA-UserTrackingStream", "UserTrackingStream"]
    return userTrackingStreams.some((stream) => SchemaUtil.containsStream(stream, schemaDefinition))
  }

  static isSla = (schemaDefinition) => {
    return schemaDefinition && schemaDefinition.slaSupportLevel === "full"
  }

  static anyPiiFieldInSchema = (schemaDefinition) => {
    // no schema definition available
    if(!schemaDefinition) {
      return false
    }

    //iterate through all properties
    for(let field in schemaDefinition.properties) {
      const property = schemaDefinition.properties[field]
      //return as soon as a PII field is found
      if (property.pii === true) {
        return true
      }
      // search nested properties
      if (property.properties && SchemaUtil.anyPiiFieldInSchema(property)) {
        return true
      }
    }

    return false;
  }

  static anyPiiField = (schemaDefinition) => {
    return SchemaUtil.anyPiiFieldInSchema(schemaDefinition)
  }

  static isBackwardsCompatible = (schemaDefinition) => {
    return schemaDefinition && schemaDefinition.compatibility === "BACKWARD"
  }

  static DEV_PREFIXES = ["ml", "lapin", "webml"]
  static QA_PREFIXES = ["hare"]

  static getEnvironmentFromFeedName = (feedName) => {
    if (!feedName) return

    if(this.isDevFeed(feedName))
      return "DEV"
    else if(this.isQaFeed(feedName))
      return "QA"
    else
      return "PROD"
  }

  static getPrefix(feedName, prefixes) {
    let defaultResponse = ""
    if(!feedName || !prefixes) return defaultResponse
    for (let prefix of prefixes) {
      if(feedName.startsWith(prefix)) {
        return prefix
      }
    }
    return defaultResponse
  }

  static isQaFeed(feedName) {
    return this.getPrefix(feedName, this.QA_PREFIXES) !== ""
  }

  static isDevFeed(feedName) {
    return this.getPrefix(feedName, this.DEV_PREFIXES) !== ""
  }

  static isProdFeed(feedName) {
    return !this.isQaFeed(feedName) && !this.isDevFeed(feedName)
  }

  static getFeedNameForEnvironment(originalFeedName, environment, allFeedNames) {
    const notFoundResponse = undefined
    if(!environment || !originalFeedName) return notFoundResponse

    let prefix = this.getPrefix(originalFeedName, this.QA_PREFIXES) + this.getPrefix(originalFeedName, this.DEV_PREFIXES)

    let feedNameWithoutPrefix = prefix ? originalFeedName.substring(prefix.length+1) : originalFeedName

    for (const feedName of allFeedNames) {
      let isFeedOfDesiredEnvironment = environment === "QA" ? this.isQaFeed(feedName) :
        environment === "DEV" ? this.isDevFeed(feedName) : this.isProdFeed(feedName)
      if(isFeedOfDesiredEnvironment && feedName.includes(feedNameWithoutPrefix)) {
        return feedName;
      }
    }

    return notFoundResponse
  }

  static getSchemaWithVersionRequest(schemaName, schemaVersion) {
    const splitVersion = schemaVersion.label.split(".")
    return {
      "rawSchemaName": schemaName.label,
      "majorSchemaVersion": splitVersion[0],
      "minorSchemaVersion": splitVersion[1]
    };
  }

  static getSchemaNameWithMajorVersion(schemaName, schemaVersion) {
    if (!schemaName || !schemaVersion) return;

    const splitVersion = schemaVersion.split(".");
    const majorVersion = splitVersion[0];
    if (!isNaN(majorVersion) && Number(majorVersion) > 1) {
      return schemaName + "__" + majorVersion;
    }

    return schemaName;
  }

  static getProdSchemaName(originalFeedName) {
    const prefix = this.getPrefix(originalFeedName, this.QA_PREFIXES) + this.getPrefix(originalFeedName, this.DEV_PREFIXES);
    return prefix ? originalFeedName.substring(prefix.length+1) : originalFeedName
  }

  static getSchemaPathFromRepoUrl(repoUrl) {
    var matches = repoUrl.match("https://gitlab.dev.tripadvisor.com/project-tahoe/event-schemas/-/blob/master/schemas/(.*).json")
    if (matches && matches.length > 1) {
      return matches[1]
    }
    else return undefined
  }
}

export default SchemaUtil;