/**
 * A simple Box for containing additional elements.
 *
 * -- Props --
 * | Name       | Type    | Required  | Default Value | Description
 * |------------|---------|-----------|---------------|---------------
 * | id         | string  | false     | null          | An id to apply to this component.
 * | className  | string  | false     | ""            | Additional classes to apply to this component.
 */
export const Box = (props) => {
  return (
    <div id={props.id || null} className={`box${props.className ? " " + props.className : ""}`}>
      {props.children}
    </div>
  );
}

export default Box;
