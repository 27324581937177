import { AuthenticatedTemplate } from '@azure/msal-react';

import { Login } from '../../Login.jsx'
import PageHeader from "./PageHeader";
import SchemaForm from "./SchemaForm";
import { useEffect } from "react";

export const PageLayoutSchema = () => {
  useEffect(() => {
    document.title = 'Schema Viewer';
  }, []);

  return (
    <>
      <Login/>
      <AuthenticatedTemplate>
        <div className="container">
          <PageHeader />
          <hr />
          <SchemaForm />
        </div>
      </AuthenticatedTemplate>
    </>
  );
}