import { useEffect, useState } from "react";
import SchemaDefinition from "./SchemaDefinition";
import JsonSchemaValidator from "./JsonSchemaValidator";
import 'react18-json-view/src/style.css'
import '../../index.css';
import '../css/schema.css';
import useFetchWithMsal from "../../useFetchWithMsal";
import { event_schema_api_urls } from "./common/Constants";
import { useSearchParams } from "react-router-dom";
import Tag from "../../components/common/elements/Tag";
import TagGroup from "../../components/common/elements/TagGroup";
import Tags from "../../components/common/elements/Tags";
import ExclusiveRadio from "../../stream/components/form/ExclusiveRadio";

import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import Icon from "../../components/common/elements/Icon";
import SchemaUtil from "../utils/SchemaUtil";
import SchemaVersionComparator from "./SchemaVersionComparator";
import LabelAndSelect from "./LabelAndSelect";
import EventIngestionCounter from "./EventIngestionCounter";

const SchemaForm = () => {
  const [loadingSchemaNames, setLoadingSchemaNames] = useState(true);
  const [schemaNameOptions, setSchemaNameOptions] = useState([]);
  const [schemaNameOptionSelected, setSchemaNameOptionSelected] = useState(undefined);

  const [majorSchemaVersionsBySchemaName, setMajorSchemaVersionsBySchemaName] = useState({});

  const [loadingSchemaVersions, setLoadingSchemaVersions] = useState(false);
  const [schemaVersionOptions, setSchemaVersionOptions] = useState([]);
  const [schemaVersionOptionSelected, setSchemaVersionOptionSelected] = useState(undefined);

  const [compareVersions, setCompareVersions] = useState(false);

  const [schemaDefinition, setSchemaDefinition] = useState(null);

  const [rawJsonString, setRawJsonString] = useState('');

  const [eventIngestionCounter, setEventIngestionCounter] = useState([]);
  const [schemaBlinkId, setSchemaBlinkId] = useState('');
  const [eventBlinkId, setEventBlinkId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const urlSchemaParam = "schema"

  const defaultEnvironment = SchemaUtil.getEnvironmentFromFeedName(searchParams.get(urlSchemaParam)) ?? "PROD"
  const [filters, setFilters] = useState({ environment: defaultEnvironment });
  const { execute } = useFetchWithMsal();

  useEffect(() => {
    execute('GET', event_schema_api_urls.listAllSchemaNamesAndMajorVersions)
      .then((schemaNamesAndMajorVersions) => {
        const schemaInUrl = searchParams.get(urlSchemaParam)

        // If the schemaNamesAndMajorVersions is empty, ignore
        if (!schemaNamesAndMajorVersions || schemaNamesAndMajorVersions.length === 0) {
          return;
        }

        const schemaNameOptionsVar = []
        const schemaMajorVersionsVar = {}
        Object.keys(schemaNamesAndMajorVersions).forEach(function(schemaName) {
          if (schemaName.includes("enriched")) {
            return;
          }
          const schemaNameOption = getSchemaNameOptionFromString(schemaName)
          if (schemaInUrl && schemaName === schemaInUrl) {
            setSchemaNameOptionSelected(schemaNameOption)
          }
          schemaNameOptionsVar.push(schemaNameOption)

          const majorVersions = schemaNamesAndMajorVersions[schemaName].sort((a, b) => b - a);

          for (const majorVersion of majorVersions) {
            const majorVersionOption = { value: majorVersion, label: majorVersion }
            // check if the schemaName already exists in the object, and if not initialize it
            if (!Object.hasOwn(schemaMajorVersionsVar, schemaName)) {
              schemaMajorVersionsVar[schemaName] = []
            }
            schemaMajorVersionsVar[schemaName].push(majorVersionOption)
          }

        });

        setSchemaNameOptions(schemaNameOptionsVar);
        setMajorSchemaVersionsBySchemaName(schemaMajorVersionsVar)

        setLoadingSchemaNames(false);

      })
      .catch((error) => {
        console.error(error);
      });

  }, [execute])

  function getSchemaNameOptionFromString(schemaName) {
    if (!schemaName) {
      return undefined
    }
    return {
      value: schemaName,
      label: schemaName
    }
  }

  useEffect(() => {
    setSchemaVersionOptionSelected(null)
    setSchemaDefinition(null)
    setLoadingSchemaVersions(true)

    addParamToUrl(urlSchemaParam, schemaNameOptionSelected?.label)

    if (!schemaNameOptionSelected)
      return

    getAllVersionsOptions()
      .then(fullVersionOptions => {
        setSchemaVersionOptions(fullVersionOptions)
        setSchemaVersionOptionSelected(fullVersionOptions[0])
        setLoadingSchemaVersions(false)
      });
  }, [schemaNameOptionSelected]);

  useEffect(() => {
    setSchemaDefinition(null)

    if (schemaNameOptionSelected && schemaVersionOptionSelected) {
      updateSchemaDefinitionForSchemaAndVersion();
      getEventIngestionCounter();
    }

  }, [schemaNameOptionSelected, schemaVersionOptionSelected]);

  useEffect(() => {
    // we don't want to clear if it's the first render (no options are available yet)
    if(schemaNameOptions.length !== 0) {
      // when changing filters we want to reset the selected schema name (it might no longer be available)
      const feedNameForEnvironment = SchemaUtil.getFeedNameForEnvironment(schemaNameOptionSelected?.label, filters.environment, schemaNameOptions.map(value => value.label));
      setSchemaNameOptionSelected(getSchemaNameOptionFromString(feedNameForEnvironment))
    }
  }, [filters]);

  async function getAllVersionsOptions() {
    const majorVersionOptionsForSchema = majorSchemaVersionsBySchemaName[schemaNameOptionSelected.label];

    const schemaVersionOptionsVar = []
    for (const majorOption of majorVersionOptionsForSchema) {
      const majorSchemaVersion = majorOption.label;
      await loadMinorSchemaVersions(majorSchemaVersion)
        .then(minorVersions => {
          for (let minorSchemaVersion of minorVersions) {
            const fullVersion = majorSchemaVersion + "." + minorSchemaVersion
            const schemaVersionOption = { value: fullVersion, label: fullVersion }
            schemaVersionOptionsVar.push(schemaVersionOption)
          }
        });
    }
    return schemaVersionOptionsVar
  }


  function addParamToUrl(key, value) {

    const url = new URL(window.location.href);
    if (value) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key)
    }
    window.history.replaceState(null, null, url); //prevents page reload

  }

  function loadMinorSchemaVersions(majorSchemaVersion) {
    return execute('GET', event_schema_api_urls.listAllMinorVersions + "?schema-name=" + schemaNameOptionSelected.label + "&schema-major-version=" + majorSchemaVersion)
      .then((data) => {
        // If the data is not an array of strings (schema names), it won't move forward
        if (!data || data.length === 0 || typeof data[0] !== 'number') {
          return;
        }
        data = data.sort(function (a, b) {
          return b - a ;
        })

        return data;

      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }

  const getEventIngestionCounter = () => {
    const postBody = SchemaUtil.getSchemaWithVersionRequest(schemaNameOptionSelected, schemaVersionOptionSelected);
    execute('POST', event_schema_api_urls.getEventIngestionCounter, postBody)
      .then((data) => {
        setEventIngestionCounter(data);
        setEventBlinkId('fade-it')
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setTimeout(() => {
          setEventBlinkId('');
        }, 1000);
      });
  }

  const updateSchemaDefinitionForSchemaAndVersion = () => {
    const postBody = SchemaUtil.getSchemaWithVersionRequest(schemaNameOptionSelected, schemaVersionOptionSelected);
    execute('POST', event_schema_api_urls.getSpecificSchemaDefinition, postBody)
      .then((data) => {
        setSchemaDefinition(data);
        setSchemaBlinkId('fade-it');
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setTimeout(() => {
          setSchemaBlinkId('');
        }, 1000);
      });
  }

  const getTags = () => {
    const tagsInfo = []
    if (schemaDefinition) {
      // Checking streaming tag
      if (SchemaUtil.isKinesisDriven(schemaDefinition)) {
        tagsInfo.push({ info: "Origin", text: "Streaming", tooltip: "This feed is Kinesis-driven" })
      } else {
        tagsInfo.push({ info: "Origin", text: "Log", tooltip: "This feed is log-driven" })
      }

      if (SchemaUtil.isRETL(schemaDefinition)) {
        tagsInfo.push({ info: "rETL", text: "Yes", tooltip: "This feed can be ingested through rETL" })
      } else {
        tagsInfo.push({ info: "rETL", text: "No", tooltip: "This feed can not be ingested through rETL" })
      }

      if (SchemaUtil.isSla(schemaDefinition))
        tagsInfo.push({ info:"SLA", text: "Full", tooltip: "This feed is SLA" })
      else
        tagsInfo.push({ info:"SLA",  text: "None", tooltip: "This feed is non-SLA" })

      if(SchemaUtil.anyPiiField(schemaDefinition)) {
        tagsInfo.push({ info: "PII", text: "Yes", tooltip: "This feed contains PII fields" })
      } else {
        tagsInfo.push({ info: "PII", text: "No", tooltip: "This feed doesn't contain PII fields" })
      }

      if(SchemaUtil.isBackwardsCompatible(schemaDefinition)) {
        tagsInfo.push({ info: "Compatibility", text: "Backward", tooltip: "This schema requires updates to be backwards-compatible" })
      } else {
        tagsInfo.push({ info: "Compatibility", text: "None", tooltip: "This schema doesn't require updates to be backwards-compatible" })
      }

      var environment = SchemaUtil.getEnvironmentFromFeedName(schemaDefinition?.feed);
      environment = environment === "PROD" ? "Production" : environment;
      tagsInfo.push({ info: "Environment", text: environment, tooltip: `This feed is for data in the ${environment} environment` })

      if(SchemaUtil.isUserTrackingFeed(schemaDefinition)) {
        tagsInfo.push({ info: "Type", text: "Enterprise", tooltip: "Enterprise tracking feed" })
      } else {
        tagsInfo.push({ info: "Type", text: "Domain", tooltip: "Domain tracking feed" })
      }
    }

    return tagsInfo
  }

  const getSnowflakeDestinations = () => {
    const fullyQualifiedSnowflakeTables = [];
    if (schemaDefinition.snowflakeDestinations) {
      schemaDefinition.snowflakeDestinations.forEach(destination => fullyQualifiedSnowflakeTables.push(`${destination.database}.` + `${destination.schema}.` + `${destination.table}`.toUpperCase()));
    } else {
      if (schemaDefinition.feedEnabled && schemaDefinition.feedEnabled === "true") {
        fullyQualifiedSnowflakeTables.push(`${schemaDefinition.snowflakeDatabase}.${schemaDefinition.snowflakeSchema}.${schemaDefinition.snowflakeTable}`.toUpperCase());
      }
    }

    return fullyQualifiedSnowflakeTables;
  }

  let schemaTitle = "Select a schema";
  let schemaDescription = "";
  if (schemaDefinition) {
    schemaTitle = schemaDefinition.title ? schemaDefinition.title : schemaNameOptionSelected.label;
    schemaDescription = schemaDefinition.description ? schemaDefinition.description : "";
  }


  const updateEnvironmentFilter = (environment) => {
    if(environment !== filters.environment) {
      const newFilters = {}
      Object.assign(newFilters, filters)
      newFilters.environment = environment
      setFilters(newFilters)
    }
  }

  const getFilteredOptions = () => {
    return schemaNameOptions.filter(option => SchemaUtil.getEnvironmentFromFeedName(option.label) === filters.environment)
  }


  return (
    <section className="schema-form">
      <div className="container mt-6">
        {
          compareVersions &&
                <SchemaVersionComparator
                  schemaName={schemaNameOptionSelected ? schemaNameOptionSelected.label : null}
                  schemaVersionOptions={schemaVersionOptions}
                  schemaVersionOptionSelected={schemaVersionOptionSelected}
                  onClose={() => setCompareVersions(false)}
                  className={"is-active"}/>
        }
        <div className="columns">
          <ExclusiveRadio className="column" title={"Environment:"} options={["PROD", "QA", "DEV"]}
            defaultOption={filters.environment}
            onChange={updateEnvironmentFilter}/>
        </div>
        <div className="columns">
          <div className="column is-6">
            <LabelAndSelect
              label={"Schema Name"}
              loading={loadingSchemaNames}
              isClearable={true}
              isSearchable={true}
              options={getFilteredOptions()}
              value={schemaNameOptionSelected}
              onChange={(selected) => {
                setSchemaNameOptionSelected(selected)
                setSchemaVersionOptionSelected(null)
              }}
              placeholder={"Type to select a JSON schema"}
              isDisabled={false}
            />

          </div>
          <div className="column is-4">
            <LabelAndSelect
              label={"Schema Version"}
              loading={schemaNameOptionSelected && loadingSchemaVersions}
              isClearable={false}
              isSearchable={true}
              options={schemaVersionOptions}
              value={schemaVersionOptionSelected}
              onChange={(selected) => {
                setSchemaVersionOptionSelected(selected)
              }}
              placeholder={schemaNameOptionSelected ? "Select a schema version" : "Select a schema first"}
              isDisabled={!schemaNameOptionSelected}
            />
          </div>
          <div className="column is-2">
            <button className="button is-info is-fullwidth is-outlined"
              onClick={() => setCompareVersions(true)}
              disabled={!schemaNameOptionSelected}
            > {schemaNameOptionSelected ? "Compare versions" : "Select a schema first"} </button>
          </div>
        </div>
        {
          getTags().length > 0 &&
          <>
            <div className="schema-tags">
              <TagGroup>
                {getTags().map((tagInfo) => <div key={tagInfo.info} className="control">
                  <Tags className="has-addons">
                    <Tag className="is-info has-text-info-light is-medium">
                      {tagInfo.info}
                    </Tag>
                    <Tag tooltip={tagInfo.tooltip} className="is-medium">
                      {tagInfo.text}
                    </Tag>
                  </Tags>
                </div>
                )}
              </TagGroup>
            </div>
            {
              getSnowflakeDestinations().length > 0 &&
              <div className="snowflake-destinations">
                {getSnowflakeDestinations().map((snowflakeDestination) => <Tags key={snowflakeDestination}
                  className="has-addons control">
                  <Tag className="is-info has-text-info-light is-medium">
                    <Icon icon={faDatabase}/>
                  </Tag>
                  <Tag tooltip={"The Snowflake destination"} className="is-medium">
                    {snowflakeDestination}
                  </Tag>
                </Tags>)}
              </div>
            }
          </>
        }
        <div className="columns">
          <div className="column">
            <article className="card">
              <div className="card-header">
                <div className="card-header-title">
                  {schemaTitle}
                </div>
              </div>
              <div className="card-content" id={schemaBlinkId}>
                {
                  schemaDescription &&
                  <div>
                    <p className="is-size-6">
                      {schemaDescription}
                    </p>
                    <hr/>
                  </div>
                }

                {
                  schemaDefinition &&
                  <SchemaDefinition
                    schemaDefinition={schemaDefinition}
                    schemaName={schemaNameOptionSelected?.label}
                    schemaVersion={schemaVersionOptionSelected?.label}
                  />
                }
              </div>
            </article>
            { schemaNameOptionSelected && eventIngestionCounter && Array.isArray(eventIngestionCounter) && eventIngestionCounter.length > 0 && (
              <article className="card mt-5">
                <div className="card-header">
                  <div className="card-header-title">{schemaNameOptionSelected.label} metadata</div>
                </div>
                <div className="card-content" id={eventBlinkId}>
                  {

                    (
                      <>
                        <nav className="level">
                          <div className="level-left">
                            <div className="level-item">
                              <button data-tooltip="Reload will take effect every 5 mins" className="button is-link is-outlined is-fullwidth"
                                onClick={() => getEventIngestionCounter()}>
                                  Click to see updates
                              </button>
                            </div>
                          </div>
                        </nav>
                        <EventIngestionCounter eventIngestionCounter={eventIngestionCounter}/>
                      </>
                    )
                  }
                </div>
              </article>
            )
            }
          </div>
          <div className="column">
            <article className="card">
              <div className="card-header">
                <div className="card-header-title">Event Validator</div>
              </div>
              <div className="card-content">
                <textarea className="textarea" placeholder="Type in a valid JSON" rows={8}
                  value={rawJsonString}
                  onChange={e => setRawJsonString(e.target.value)}></textarea>
                <JsonSchemaValidator
                  schemaName={schemaNameOptionSelected ? schemaNameOptionSelected.label : null}
                  schemaVersion={schemaVersionOptionSelected}
                  rawJsonString={rawJsonString}/>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SchemaForm 