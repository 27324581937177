/**
 * A modal.
 *
 * -- Props --
 * | Name       | Type      | Required  | Default Value | Description
 * |------------|-----------|-----------|---------------|---------------
 * | id         | string    | false     | null          | An id to apply to this component.
 * | className  | string    | false     | ""            | Additional classes to apply to this component.
 * | onClose    | function  | true      | N/A           | An onClose function.
 */
export const Modal = (props) => {
  return (
    <div id={props.id || null} className={`modal${props.className ? " " + props.className : ""}`}>
      <div className="modal-background" onClick={props.onClose}></div>
      {props.children}
    </div>
  );
}

export default Modal;
