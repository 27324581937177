/**
 * Columns for organizing layout.
 *
 * -- Props --
 * | Name       | Type    | Required  | Default Value | Description
 * |------------|---------|-----------|---------------|---------------
 * | id         | string  | false     | null          | An id to apply to this component.
 * | className  | string  | false     | ""            | Additional classes to apply to this component.
 */
export const Columns = (props) => {
  return (
    <div id={props.id || null} className={`columns${props.className ? " " + props.className : ""}`}>
      {props.children}
    </div>
  );
}

export default Columns;