import { useEffect, useState } from "react";

export const ExclusiveRadio = (props) => {
  const [checkedOption, setCheckedOption] = useState(props.defaultOption ? props.defaultOption : props.options[0])

  const getOptionTextWeight = (option) => {
    return checkedOption === option ? " has-text-weight-medium" : ""
  }

  useEffect(() => {
    if(checkedOption) {
      props.onChange(checkedOption)
    }
  }, [checkedOption]);

  return (
    <div className={`control ${props.className ? " " + props.className : ""}`}>
      <span className="has-text-weight-bold mr-2">{props.title ? props.title : "Options"}</span>
      {props.options?.map((option, index) => <label key={index} className="radio mr-2">
        <input type="radio" name={props.title} checked = {checkedOption === option} onClick={() => setCheckedOption(option)}/>
        <span className={"ml-2"+getOptionTextWeight(option)}>
          {option}
        </span>
      </label>)}
    </div>
  );
}

export default ExclusiveRadio;