import Columns from "../../components/common/layout/Columns";
import Column from "../../components/common/layout/Column";
import Container from "../../components/common/layout/Container";
import Hero from "../../components/common/layout/Hero";
import HeroBody from "../../components/common/layout/HeroBody";
import HomeCard from "./HomeCard";

import { faBinoculars, faFileContract, faBook } from "@fortawesome/free-solid-svg-icons";
import "../css/home.css";

const HomeBody = () => {
  return (
    <Container>
      <Hero>
        <HeroBody>
          <p className="title is-1">Event Service</p>
          <p className="subtitle">Streaming ingestion of live site data.</p>
        </HeroBody>
      </Hero>
      <Columns>
        <Column className="is-one-third">
          <HomeCard title="Event Viewer"
            description="View events for a given stream."
            href="/event-viewer"
            icon={faBinoculars}
            buttonText="Event Viewer"
            buttonTarget="_self"
          />
        </Column>
        <Column className="is-one-third">
          <HomeCard title="Schema Viewer"
            description="View all available schema definitions and validate sample events."
            href="/event-schema"
            icon={faFileContract}
            buttonText="Schema Viewer"
            buttonTarget="_self"
          />
        </Column>
        <Column className="is-one-third">
          <HomeCard title="Documentation"
            description="View all Event Service documentation."
            href="https://tripdocs.pages.tamg.io/data-platform/docs/tracking/enterprisetracking/overview"
            icon={faBook}
            buttonText="Documentation"
          />
        </Column>
      </Columns>
    </Container>
  );
};

export default HomeBody;
