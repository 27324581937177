const EventIngestionCounter = ({ eventIngestionCounter }) => {
  return (
    <>
      <table className="table is-bordered is-hoverable is-striped is-fullwidth">
        <thead>
          <tr>
            <th className="is-link">Stream Names</th>
            <th className="is-link">Time Offset</th>
            <th className="is-link">Event Count</th>
          </tr>
        </thead>
        <tbody>
          {
            eventIngestionCounter.map((entry, i) => (
              <tr key={i}>
                <td>
                  {entry['streamNames'].join(' & ')}
                </td>
                <td>
                  {entry['metricOffsetInfo']['key']}
                </td>
                <td>
                  {
                  // show commas as thousands separators
                    entry['metricValue'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  )
}

export default EventIngestionCounter;